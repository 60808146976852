exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-doc-jsx-content-file-path-content-components-alert-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/home/runner/work/tidy-ui-docs/tidy-ui-docs/content/components/alert.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-content-components-alert-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-content-components-avatar-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/home/runner/work/tidy-ui-docs/tidy-ui-docs/content/components/avatar.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-content-components-avatar-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-content-components-badge-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/home/runner/work/tidy-ui-docs/tidy-ui-docs/content/components/badge.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-content-components-badge-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-content-components-border-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/home/runner/work/tidy-ui-docs/tidy-ui-docs/content/components/border.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-content-components-border-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-content-components-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/home/runner/work/tidy-ui-docs/tidy-ui-docs/content/components.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-content-components-mdx" */),
  "component---src-templates-doc-jsx-content-file-path-content-getting-started-mdx": () => import("./../../../src/templates/doc.jsx?__contentFilePath=/home/runner/work/tidy-ui-docs/tidy-ui-docs/content/getting-started.mdx" /* webpackChunkName: "component---src-templates-doc-jsx-content-file-path-content-getting-started-mdx" */)
}

